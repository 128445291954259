import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACL } from '@proman/services/acl.service';

@Component({
    selector: 'pm-entity-category-recursive',
    template: `
        @if ((category.isActiveParent || category.isEnabled) || isEditMode) {
            <div class="box-one"
                 fxLayout="column">
                <div fxLayout="column">
                    @if (isEditMode) {
                        <div fxLayout="column">
                            <pro-checkbox fxFlex
                                          [config]="{ label: category.name }"
                                          [value]="category.isEnabled"
                                          (onChange)="handleToggle('categories', category, !$event)"
                                          [disabled]="!isEditMode"></pro-checkbox>
                        </div>
                    } @else {
                        <div fxLayout="column">
                            {{ category.id }} - {{ category.name }}
                        </div>
                    }
                </div>
                
                @if (category.__children?.length) {
                    <div fxLayout="column"
                         class="_category-children">
                        @for (_category of category.__children; track _category) {
                            <div>
                                <pm-entity-category-recursive [category]="_category" [isEditMode]="isEditMode" (toggle)="handleToggle($event.field, $event.category, $event.value)"></pm-entity-category-recursive>
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: [`
        ._category-children {
            margin: 8px 0;
            padding: 0px 16px;
            border-left: 2px dashed #BFBFBF;
        }
    `]
})

export class EntityParentCategoryRecursiveComponent implements OnInit {
    @Input() category: any;
    @Input() isEditMode: boolean;
    @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

    constructor(
      public ACL: ACL,
    ) {

    }

    ngOnInit() {

    }

    handleToggle = (field: string, category: any, value: boolean) => {
        this.toggle.emit({ field, category, value });
    };

}
